import React, { Suspense, lazy } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import DashboardPage from "./dashboard/DashboardPage";
import { LayoutSplashScreen } from "../../../_metronic";

const AdminPage = lazy(() => import("./admin/AdminPage"));
const GroupUserPage = lazy(() => import("./group/GroupUserPage"));
const ReportPage = lazy(() => import("./report/ReportPage"));
const UserPage = lazy(() => import("./user/UserPage"));
const ProjetoPage = lazy(() => import("./projeto/ProjetoPage"));
const CanalPage = lazy(() => import("./canal/CanalPage"));
const PlanoPage = lazy(() => import("./plano/PlanoPage"));
const AssinaturaPage = lazy(() => import("./assinatura/AssinaturaPage"));
const LeituraPage = lazy(() => import("./leitura/LeituraPage"));
const DocumentoPage = lazy(() => import("./documento/DocumentoPage"));
const PagamentoPage = lazy(() => import("./pagamento/PagamentoPage"));

export default function HomePage() {
    return (
        <Suspense fallback={<LayoutSplashScreen />}>
            <Switch>
                <Redirect exact from="/" to="/dashboard/projetos" />

                <Route path="/dashboard" component={DashboardPage} />
                <Route path="/admin" component={AdminPage} />
                <Route path="/group" component={GroupUserPage} />
                <Route path="/report" component={ReportPage} />
                <Route path="/user" component={UserPage} />
                <Route path="/projeto" component={ProjetoPage} />
                <Route path="/canal" component={CanalPage} />
                <Route path="/plano" component={PlanoPage} />
                <Route path="/assinatura" component={AssinaturaPage} />
                <Route path="/leitura" component={LeituraPage} />
                <Route path="/documento" component={DocumentoPage} />
                <Route path="/pagamento" component={PagamentoPage} />
                <Redirect to="/error/error-v6" />
            </Switch>
        </Suspense>
    );
}
