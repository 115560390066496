/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/pages/auth/AuthPage`, `src/pages/home/HomePage`).
 */

import React from "react";
import { Redirect, Route, Switch, useLocation, withRouter } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { useLastLocation } from "react-router-last-location";
import HomePage from "../pages/home/HomePage";
import ErrorsPage from "../pages/errors/ErrorsPage";
import LogoutPage from "../pages/auth/Logout";
import { LayoutContextProvider } from "../../_metronic";
import Layout from "../../_metronic/layout/Layout";
import * as routerHelpers from "../router/RouterHelpers";
import AuthPage from "../pages/auth/AuthPage";
import ForgotPassword from "../pages/password/ForgotPassword";
import ResetPassword from "../pages/password/ResetPassword";
import CodigoSenhaPage from "../pages/auth/CodigoSenhaPage";

export const Routes = withRouter(({ history }) => {
    const lastLocation = useLastLocation();
    routerHelpers.saveLastLocation(lastLocation);
    const { isAuthorized, menuConfig, userLastLocation } = useSelector(
        ({ auth, builder: { menuConfig } }) => ({
            menuConfig,
            isAuthorized: auth.user != null,
            userLastLocation: routerHelpers.getLastLocation() == '/codigo-senha' ? '/dashboard' : routerHelpers.getLastLocation(),
        }),
        shallowEqual
    );

    return (
        <LayoutContextProvider history={history} menuConfig={menuConfig}>
            <Switch>
                {!isAuthorized && useLocation().pathname === "/password/forgot" ? <Route path="/password/forgot" component={ForgotPassword} /> : null}

                {!isAuthorized && useLocation().pathname === "/password/reset" ? <Route path="/password/reset" component={ResetPassword} /> : null}

                {!isAuthorized && useLocation().pathname === "/codigo-senha" ? <Route path="/codigo-senha" component={CodigoSenhaPage} /> : null}

                {!isAuthorized ? <AuthPage /> : <Redirect from="/auth" to={userLastLocation} />}

                <Route path="/error" component={ErrorsPage} />
                <Route path="/logout" component={LogoutPage} />

                {!isAuthorized ? (
                    <Redirect to="/auth/login" />
                ) : (
                    <Layout>
                        <HomePage userLastLocation={userLastLocation} />
                    </Layout>
                )}
            </Switch>
        </LayoutContextProvider>
    );
});
