import React, { Component } from "react";
import { Link } from "react-router-dom";
import { toAbsoluteUrl } from "../../../_metronic";
import BetterBox from "../../components/BetterBox";
import BetterAlerts from "../../components/BetterAlerts";
import BetterCircularProgress from "../../components/BetterCircularProgress";
import { forgotPassword } from "../../crud/auth.crud";
import { TextField } from "@material-ui/core";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import { Button } from "react-bootstrap";

class ForgotPassword extends Component {
    constructor() {
        super();

        this.state = {
            email: "",
            loading: false,
            submitted: false,
        };
    }

    onSubmit = () => {
        if (!this.state.email) {
            this.setState({ errors: ["Email é obrigatório."] });
            return;
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(this.state.email)) {
            this.setState({ errors: ["Endereço de e-mail inválido."] });
            return;
        }

        this.setState({ submitted: true });

        forgotPassword({ email: this.state.email })
            .then(async (res) => {
                if (res.data.errors) {
                    return Swal.fire("Oops!", res.data?.errors?.[0] ?? "Parece ter ocorrido um problema. Por favor, contate o suporte.", "error");
                }

                if (res.data.email) {
                    return Swal.fire("Oops!", res.data.email ?? "Parece ter ocorrido um problema. Por favor, contate o suporte.", "error");
                }

                window.scrollTo(0, 0);

                await Swal.fire({
                    icon: "success",
                    title: "Sucesso!",
                    text: "Um email foi enviado para atualizar sua senha.",
                    showConfirmButton: false,
                    timer: 1500,
                });

                window.location.href = `/codigo-senha?email=${this.state.email}`;
            })
            .catch((err) => {
                if (err.response?.status === 422) {
                    Swal.fire("Oops!", err.response.data.errors[0], "error");
                } else {
                    Swal.fire("Oops!", "Parece ter ocorrido um problema. Por favor, contate o suporte.", "error");
                }

                this.setState({ submitted: false });
            })
            .finally(() => {
                this.setState({ submitted: false });
            });
    };

    render() {
        return (
            <div style={{ margin: "50px 0px 50px 0px" }}>
                <div className="row" style={{ margin: "0" }}>
                    <div className="col-sm-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3">
                        <div className="row">
                            <div className="col-sm-12">
                                <center>
                                    <img alt="Logo" src={toAbsoluteUrl("/images/logo-dark.png")} style={{ width: "160px", height: "auto", marginTop: "35px", marginBottom: "35px" }} />

                                    <p style={{ padding: "10px 50px 15px 50px", fontSize: "18px", color: "#424242", fontWeight: "500" }}>
                                        <b>Olá!</b> Por favor, insira seu email e enviaremos um link para recuperar sua senha.
                                    </p>
                                </center>
                            </div>

                            <div className="col-sm-12">
                                <BetterCircularProgress loading={this.state.loading}>
                                    <div>
                                        <div className="col-sm-12">
                                            <BetterAlerts errors={this.state.errors} />
                                        </div>

                                        <div>
                                            <div className="col-sm-12">
                                                <BetterBox title="E-mail" >
                                                    <div className="col-sm-12">
                                                        <div className="form-group fg-line">
                                                            <TextField type="email" name="email" label="E-mail *" margin="normal" variant="outlined" onBlur={(event) => this.setState({ email: event.target.value })} onChange={(event) => this.setState({ email: event.target.value })} />
                                                        </div>
                                                    </div>
                                                </BetterBox>
                                            </div>

                                            <div className="col-sm-12">
                                                <center>
                                                    <br />
                                                    <Button className="btn btn-primary btn-lg btn-bold" onClick={() => this.onSubmit()}>
                                                        <i className={`margin-icon ${this.state.submitted ? "fas fa-sync fa-spin" : "fa fa-check-square"}`}></i>Enviar
                                                    </Button>

                                                    <br />
                                                    <br />

                                                    <Link to="/auth/login" className="btn btn-secondary btn-lg btn-bold">
                                                        Voltar
                                                    </Link>
                                                </center>
                                            </div>
                                        </div>
                                    </div>
                                </BetterCircularProgress>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(null, null)(ForgotPassword);
