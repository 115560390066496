import React, { Component } from "react";
import { Portlet, PortletBody } from "../../../partials/content/Portlet";
import { getDashboard } from "../../../crud/dashboard.crud";
import BetterCircularProgress from "../../../components/BetterCircularProgress";
import Swal from "sweetalert2";

export default class DashboardPage extends Component {
    state = {
        loading: false,
        error: false,
        data: null,
    };

    componentDidMount = () => {
        this.setState({ loading: true });

        getDashboard()
            .then((res) => {
                if (res.data.errors) {
                    return Swal.fire("Oops!", res.data.errors ?? "Parece ter ocorrido um problema. Por favor, contate o suporte.", "error");
                }

                this.setState({ ...this.state, data: res.data });
            })
            .catch(() => {
                Swal.fire("Oops!", "Parece ter ocorrido um problema. Por favor, contate o suporte.", "error");

                this.setState({ error: true });
            })
            .finally(() => {
                this.setState({ loading: false });
            });
    };

    render() {
        return (
            <BetterCircularProgress loading={this.state.loading}>
                {!this.state.error && (
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-6">
                            <Portlet className="kt-portlet--border-bottom-brand" fluidHeight={true}>
                                <PortletBody>
                                    <div className="kt-widget26">
                                        <div className="kt-widget26__content">
                                            <span className="kt-widget26__desc">{`Totalizador de usuários`}</span>
                                            <span className="kt-widget26__number">{this.state.data?.users_count}</span>
                                        </div>
                                    </div>
                                </PortletBody>
                            </Portlet>
                        </div>

                        <div className="col-sm-12 col-md-12 col-lg-6">
                            <Portlet className="kt-portlet--border-bottom-brand" fluidHeight={true}>
                                <PortletBody>
                                    <div className="kt-widget26">
                                        <div className="kt-widget26__content">
                                            <span className="kt-widget26__desc">{`Totalizador de documentos`}</span>
                                            <span className="kt-widget26__number">{this.state.data?.documento_count}</span>
                                        </div>
                                    </div>
                                </PortletBody>
                            </Portlet>
                        </div>

                        <div className="col-sm-12 col-md-12 col-lg-6">
                            <Portlet className="kt-portlet--border-bottom-brand" fluidHeight={true}>
                                <PortletBody>
                                    <div className="kt-widget26">
                                        <div className="kt-widget26__content">
                                            <span className="kt-widget26__desc">{`Totalizador de projetos`}</span>
                                            <span className="kt-widget26__number">{this.state.data?.projeto_count}</span>
                                        </div>
                                    </div>
                                </PortletBody>
                            </Portlet>
                        </div>

                        <div className="col-sm-12 col-md-12 col-lg-6">
                            <Portlet className="kt-portlet--border-bottom-brand" fluidHeight={true}>
                                <PortletBody>
                                    <div className="kt-widget26">
                                        <div className="kt-widget26__content">
                                            <span className="kt-widget26__desc">{`Totalizador de canais`}</span>
                                            <span className="kt-widget26__number">{this.state.data?.canais_count}</span>
                                        </div>
                                    </div>
                                </PortletBody>
                            </Portlet>
                        </div>
                    </div>
                )}
            </BetterCircularProgress>
        );
    }
}
