import axios from "axios";
import { Constants } from "../utils/Constants";

export function login(email, password) {
    return axios.post(`${Constants.baseUrl}/auth`, { email, password });
}

export function forgotPassword(form) {
    return axios.post(`${Constants.baseUrl}/password/forgot`, form);
}

export function resetPassword(form) {
    return axios.post(`${Constants.baseUrl}/password/reset`, form);
}

export function verificarCodigo(data) {
    return axios.post(`${Constants.baseUrl}/verificar-codigo`, data);
}