import React, { Component } from "react";
import { Link } from "react-router-dom";
import { toAbsoluteUrl } from "../../../_metronic";
import BetterBox from "../../components/BetterBox";
import BetterAlerts from "../../components/BetterAlerts";
import BetterCircularProgress from "../../components/BetterCircularProgress";
import { forgotPassword, verificarCodigo } from "../../crud/auth.crud";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import { Button } from "react-bootstrap";
import queryString from "query-string";
import PinInput from "react-pin-input";

class CodigoSenhaPage extends Component {
    constructor () {
        super();

        this.state = {
            email: "",
            codeInput: "",
            loading: false,
            submitted: false,
        };
    }

    componentDidMount = () => {
        let params = queryString.parse(this.props.location.search);
        this.setState({ email: params.email });
    };

    onSubmit = () => {
        this.setState({ submitted: true });

        verificarCodigo({ email: this.state.email, token: this.state.codeInput })
            .then(() => {
                this.props.history.push(`/password/reset?email=${this.state.email}&token=${this.state.codeInput}`);
            })
            .catch((err) => {
                Swal.fire("Ops!", err.response.status === 422 ? err.response.data.errors[0] : "Ocorreu algum erro, entre em contato com o suporte.", "error");
                this.setState({ submitted: false });
            })
            .finally(() => this.setState({ submitted: false }));
    };

    reenviarCodigo = () => {
        this.setState({ loading: true });

        forgotPassword({ email: this.state.email })
            .then(() => Swal.fire("Sucesso!", "O email com o código foi enviado.", "success"))
            .catch(() => Swal.fire("Ops!", "Ocorreu algum erro, entre em contato com o suporte.", "error"))
            .finally(() => this.setState({ loading: false }));
    };

    render() {
        return (
            <div style={{ margin: "30px 0px 30px 0px" }}>
                <div className="row" style={{ margin: "0" }}>
                    <div className="col-sm-12 col-md-8 offset-md-2 col-lg-5 offset-lg-4">
                        <div className="row">
                            <div className="col-sm-12">
                                <center>
                                    <img alt="Logo" src={toAbsoluteUrl("/images/logo-dark.png")} style={{ width: "250px", height: "auto", marginTop: "30px", marginBottom: "35px" }} />

                                    <p style={{ padding: "10px 50px 15px 50px", fontSize: "18px", color: "#424242", fontWeight: "500" }}>
                                        <b>Olá!</b> Por favor, insira o código enviado para o seu email.
                                    </p>
                                </center>
                            </div>

                            <div className="col-sm-12">
                                <BetterCircularProgress loading={this.state.loading}>
                                    <div>
                                        <div className="col-sm-12">
                                            <BetterAlerts errors={this.state.errors} />
                                        </div>

                                        <div>
                                            <div className="col-sm-12">
                                                <BetterBox>
                                                    <div className="col-sm-12">
                                                        <div className="form-group fg-line">
                                                            <PinInput
                                                                length={6}
                                                                focus
                                                                type="numeric"
                                                                onChange={(value) => this.setState({ codeInput: value })}
                                                                onComplete={(value) => this.setState({ codeInput: value })}
                                                                inputStyle={{
                                                                    fontSize: "20px",
                                                                    height: "50px",
                                                                    padding: "9px",
                                                                    MozAppearance: "textfield",
                                                                    textAlign: "center",
                                                                    width: "50px",
                                                                    border: 0,
                                                                    borderBottom: "1px solid #DCE1E2",
                                                                    color: "#101415",
                                                                    fontWeight: "700",
                                                                    lineHeight: "30px",
                                                                }}
                                                                style={{ display: 'flex', justifyContent: 'center' }}
                                                            />
                                                        </div>
                                                    </div>
                                                </BetterBox>
                                            </div>

                                            <div className="col-sm-12">
                                                <center>
                                                    <Button className="btn btn-primary btn-lg btn-bold" onClick={() => this.onSubmit()}>
                                                        <i className={`margin-icon ${this.state.submitted ? "fas fa-sync fa-spin" : "fa fa-check-square"}`}></i>Confirmar
                                                    </Button>

                                                    <div style={{ display: 'flex', justifyContent:'center', gap: '20px', marginTop: '20px' }}>
                                                        <Button onClick={this.reenviarCodigo} className="btn btn-primary btn-sm btn-bold">
                                                            Reenviar código
                                                        </Button>

                                                        <Link to="/auth/login" className="btn btn-secondary btn-sm btn-bold">
                                                            Voltar
                                                        </Link>
                                                    </div>

                                                </center>
                                            </div>
                                        </div>
                                    </div>
                                </BetterCircularProgress>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(null, null)(CodigoSenhaPage);
