export default {
    header: {
        self: {},
        items: [],
    },
    aside: {
        self: {},
        items: [
            {
                title: "Dashboard",
                root: true,
                icon: "fas fa-tachometer-alt",
                page: "dashboard",
                bullet: "dot",
            },
            {
                title: "Canais",
                root: true,
                icon: "fas fa-at",
                page: "canal",
                bullet: "dot",
            },
            {
                title: "Projetos",
                root: true,
                icon: "fas fa-calendar-check",
                page: "projeto",
                bullet: "dot",
            },
            {
                title: "Usuários",
                root: true,
                icon: "fas fa-user",
                page: "user",
                bullet: "dot",
            },
            {
                title: "Planos",
                root: true,
                icon: "fas fa-credit-card",
                page: "plano",
                bullet: "dot",
            },
            {
                title: "Relatórios",
                root: true,
                page: "report",
                icon: "fas fa-file",
                bullet: "dot",
                submenu: [
                    {
                        title: "Assinaturas",
                        bullet: "dot",
                        page: "assinatura",
                    },
                    {
                        title: "Leituras",
                        bullet: "dot",
                        page: "leitura",
                    },
                ],
            },
            {
                title: "Acessos",
                root: true,
                bullet: "dot",
                icon: "fas fa-user-shield",
                submenu: [
                    {
                        title: "Administradores",
                        bullet: "dot",
                        page: "admin",
                    },
                    {
                        title: "Grupos",
                        bullet: "dot",
                        page: "group",
                    },
                ],
            },
        ],
    },
};
